<template>
  <div>
    <div class="mb-5">
      <b-modal id="food-menu-show-modal" title="Food Menu Items" size="lg" hide-footer>
        <div v-show="show" class="alert alert-danger alert-dismissible fade show" role="alert">
        </div>
        <div class="modal-content bg-white rounded">
          <img v-if="imageSrc" :src="imageSrc" alt="Food Menu" class="img-fluid">
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
export default {
  name: 'foodMenuImageModal',
  data() {
    return {
      show: false,
      imageSrc: '',
    }
  },
  mounted() {
    this.fetchImage()
  },
  methods: {
    hide() {
      setTimeout(() => {
        this.show = false
      }, 5000)
    },
    fetchImage() {
      this.imageSrc = '/resources/images/food_menu.png'
    },
  },
}
</script>
