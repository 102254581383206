const items = [
  {
    id: 1,
    venture: 'BD',
    category: 'Snacks',
    item_name: 'Momo',
    unit_price: 105,
    vat_percentage: '%5',
    description: '4 pcs momos with green chatney, hot garlic sauce',
  },
  {
    id: 2,
    venture: 'BD',
    category: 'Snacks',
    item_name: 'Fried Chicken',
    unit_price: 95,
    vat_percentage: '%5',
    description: '1 pcs M Marinated Fried chiken',
  },
  {
    id: 3,
    venture: 'BD',
    category: 'Snacks',
    item_name: 'Chicken Popcorn',
    unit_price: 90,
    vat_percentage: '%5',
    description: '100 gm chicken stuffed popcorn',
  },
  {
    id: 4,
    venture: 'BD',
    category: 'Snacks',
    item_name: 'Spring Roll',
    unit_price: 50,
    vat_percentage: '%5',
    description: '2 pcs Filled with chicken stuffing',
  },
  {
    id: 5,
    venture: 'BD',
    category: 'Snacks',
    item_name: 'Vegetable Roll',
    unit_price: 55,
    vat_percentage: '%5',
    description: 'Filled With Vegetable Stuffing',
  },
  {
    id: 6,
    venture: 'BD',
    category: 'Snacks',
    item_name: 'Chicken Lolli-Pop',
    unit_price: 95,
    vat_percentage: '%5',
    description: '3 pcs',
  },
  {
    id: 7,
    venture: 'BD',
    category: 'Snacks',
    item_name: 'BBQ Wings',
    unit_price: 100,
    vat_percentage: '%5',
    description: 'BBQ Sauce Coated Wing',
  },
  {
    id: 8,
    venture: 'BD',
    category: 'Snacks',
    item_name: 'Meat Ball',
    unit_price: 80,
    vat_percentage: '%5',
    description: '3 Pcs',
  },
  {
    id: 9,
    venture: 'BD',
    category: 'Snacks',
    item_name: 'Prawn Tempura',
    unit_price: 110,
    vat_percentage: '%5',
    description: '3 Pcs Marinated Prawn Fried',
  },
  {
    id: 10,
    venture: 'BD',
    category: 'Snacks',
    item_name: 'Pizza',
    unit_price: 100,
    vat_percentage: '%5',
    description: '4" Pizza Filled with chicken and other Stuffings',
  },
  {
    id: 11,
    venture: 'BD',
    category: 'Snacks',
    item_name: 'Chowmein',
    unit_price: 80,
    vat_percentage: '%5',
    description: 'Stuffed with Chiken and Egg and other Vegetables',
  },
  {
    id: 12,
    venture: 'BD',
    category: 'Snacks',
    item_name: 'Rice Bowl',
    unit_price: 140,
    vat_percentage: '%5',
    description: '',
  },
  {
    id: 13,
    venture: 'BD',
    category: 'Snacks',
    item_name: 'Singara',
    unit_price: 20,
    vat_percentage: '%5',
    description: '',
  },
  {
    id: 14,
    venture: 'BD',
    category: 'Snacks',
    item_name: 'Kolija Singara',
    unit_price: 30,
    vat_percentage: '%5',
    description: '',
  },
  {
    id: 15,
    venture: 'BD',
    category: 'Snacks',
    item_name: 'Samosa',
    unit_price: 20,
    vat_percentage: '%5',
    description: '',
  },
  {
    id: 16,
    venture: 'BD',
    category: 'Snacks',
    item_name: 'Sauce',
    unit_price: 3,
    vat_percentage: '%5',
    description: 'Home Made Sauce',
  },
  {
    id: 17,
    venture: 'BD',
    category: 'Snacks',
    item_name: 'Water',
    unit_price: 20,
    vat_percentage: '%5',
    description: '500 MI',
  },
  {
    id: 18,
    venture: 'BD',
    category: 'Juice',
    item_name: 'Papaya Juice',
    unit_price: 75,
    vat_percentage: '%5',
    description: '',
  },
  {
    id: 19,
    venture: 'BD',
    category: 'Juice',
    item_name: 'Orange Juice',
    unit_price: 140,
    vat_percentage: '%5',
    description: '',
  },
  {
    id: 20,
    venture: 'BD',
    category: 'Juice',
    item_name: 'Mint Lemonade',
    unit_price: 55,
    vat_percentage: '%5',
    description: '',
  },
  {
    id: 21,
    venture: 'BD',
    category: 'Juice',
    item_name: 'Lemonade',
    unit_price: 45,
    vat_percentage: '%5',
    description: '',
  },
  {
    id: 22,
    venture: 'BD',
    category: 'Juice',
    item_name: 'Mint Lassi',
    unit_price: 75,
    vat_percentage: '%5',
    description: '',
  },
  {
    id: 23,
    venture: 'BD',
    category: 'Juice',
    item_name: 'Sweet Lassi',
    unit_price: 80,
    vat_percentage: '%5',
    description: '',
  },
  {
    id: 24,
    venture: 'BD',
    category: 'Coffee',
    item_name: 'Tea',
    unit_price: 25,
    vat_percentage: '%5',
    description: '',
  },
  {
    id: 25,
    venture: 'BD',
    category: 'Coffee',
    item_name: 'Coffee',
    unit_price: 30,
    vat_percentage: '%5',
    description: '',
  },
  {
    id: 26,
    venture: 'BD',
    category: 'Coffee',
    item_name: 'Cappuccino',
    unit_price: 200,
    vat_percentage: '%5',
    description: '',
  },
  {
    id: 27,
    venture: 'BD',
    category: 'Coffee',
    item_name: 'Americano',
    unit_price: 200,
    vat_percentage: '%5',
    description: '',
  },
  {
    id: 28,
    venture: 'BD',
    category: 'Coffee',
    item_name: 'Caffe Latte',
    unit_price: 200,
    vat_percentage: '%5',
    description: '',
  },
  {
    id: 29,
    venture: 'BD',
    category: 'Coffee',
    item_name: 'Caffe Mocha',
    unit_price: 200,
    vat_percentage: '%5',
    description: '',
  },
  {
    id: 30,
    venture: 'BD',
    category: 'Coffee',
    item_name: 'Espresso',
    unit_price: 200,
    vat_percentage: '%5',
    description: '',
  },
]

module.exports = items
